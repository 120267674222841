import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name tag
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMkgydjEwbDkuMjkgOS4yOWMuOTQuOTQgMi40OC45NCAzLjQyIDBsNi41OC02LjU4Yy45NC0uOTQuOTQtMi40OCAwLTMuNDJMMTIgMloiIC8+CiAgPHBhdGggZD0iTTcgN2guMDEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/tag
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Tag: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'M12 2H2v10l9.29 9.29c.94.94 2.48.94 3.42 0l6.58-6.58c.94-.94.94-2.48 0-3.42L12 2Z' },
    ],
    ['path', { d: 'M7 7h.01' }],
  ],
];

export default Tag;
