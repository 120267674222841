import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name subtitles
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNyAxM2g0IiAvPgogIDxwYXRoIGQ9Ik0xNSAxM2gyIiAvPgogIDxwYXRoIGQ9Ik03IDloMiIgLz4KICA8cGF0aCBkPSJNMTMgOWg0IiAvPgogIDxwYXRoIGQ9Ik0yMSAxNWEyIDIgMCAwIDEtMiAySDdsLTQgNFY1YTIgMiAwIDAgMSAyLTJoMTRhMiAyIDAgMCAxIDIgMloiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/subtitles
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Subtitles: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M7 13h4' }],
    ['path', { d: 'M15 13h2' }],
    ['path', { d: 'M7 9h2' }],
    ['path', { d: 'M13 9h4' }],
    ['path', { d: 'M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2Z' }],
  ],
];

export default Subtitles;
