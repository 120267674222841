import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name tags
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOSA1SDJ2N2w2LjI5IDYuMjljLjk0Ljk0IDIuNDguOTQgMy40MiAwbDMuNTgtMy41OGMuOTQtLjk0Ljk0LTIuNDggMC0zLjQyTDkgNVoiIC8+CiAgPHBhdGggZD0iTTYgOS4wMVY5IiAvPgogIDxwYXRoIGQ9Im0xNSA1IDYuMyA2LjNhMi40IDIuNCAwIDAgMSAwIDMuNEwxNyAxOSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/tags
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Tags: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'M9 5H2v7l6.29 6.29c.94.94 2.48.94 3.42 0l3.58-3.58c.94-.94.94-2.48 0-3.42L9 5Z' },
    ],
    ['path', { d: 'M6 9.01V9' }],
    ['path', { d: 'm15 5 6.3 6.3a2.4 2.4 0 0 1 0 3.4L17 19' }],
  ],
];

export default Tags;
