import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name truck
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNSAxOEgzYy0uNiAwLTEtLjQtMS0xVjdjMC0uNi40LTEgMS0xaDEwYy42IDAgMSAuNCAxIDF2MTEiIC8+CiAgPHBhdGggZD0iTTE0IDloNGw0IDR2NGMwIC42LS40IDEtMSAxaC0yIiAvPgogIDxjaXJjbGUgY3g9IjciIGN5PSIxOCIgcj0iMiIgLz4KICA8cGF0aCBkPSJNMTUgMThIOSIgLz4KICA8Y2lyY2xlIGN4PSIxNyIgY3k9IjE4IiByPSIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/truck
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Truck: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M5 18H3c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h10c.6 0 1 .4 1 1v11' }],
    ['path', { d: 'M14 9h4l4 4v4c0 .6-.4 1-1 1h-2' }],
    ['circle', { cx: '7', cy: '18', r: '2' }],
    ['path', { d: 'M15 18H9' }],
    ['circle', { cx: '17', cy: '18', r: '2' }],
  ],
];

export default Truck;
